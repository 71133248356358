import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import BannerMp4 from '../videos/contact.mp4'
import BannerWebm from '../videos/contact.webm'
import BannerOgv from '../videos/contact.ogv'
import BannerCover from '../videos/video_cover_contact.jpg'

export default function PrivacyPolicy() {

    return (
        <Layout>


            <Seo
                title='Privacy Policy | Webfox'
                description="Privacy Policy for Webfox Developments Limited."
            />


            {/* section1 */}
            <div>
                <section className="layer intro intro-default dark">
                    <video id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </video>

                    <div className="inner">
                        <div className="copy">

                        </div>
                    </div>
                </section>
            </div>



            <section className="layer singlecol transparent">
                <div className="inner">
                    <h1>
                        Privacy Policy
                    </h1>
                    <h4>
                        What information do we collect?
                    </h4>
                    <p>
                        We collect information from you when you register on our site, place an order, subscribe to our newsletter or fill out a form.
                    </p>
                    <p>
                        When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number. You may, however, visit our site anonymously.
                    </p>
                    <h4>
                        What do we use your information for?
                    </h4>
                    <p>
                        Any of the information we collect from you may be used in one of the following ways:
                    </p>
                    <ul>
                        <li>
                            To improve customer service. Your information helps us to more effectively respond to your customer service requests and support needs
                        </li>
                        <li>
                            To send periodic emails:
                            <ul>
                                <li>
                                    The email address you provide for registration, will only be used to send you information and updates pertaining to your order, your Webfox website account, or Webfox company newsletters. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h4>
                        How do we protect your information?
                    </h4>
                    <p>
                        We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
                    </p>
                    <h4>
                        Do we use cookies?
                    </h4>
                    <p>
                        Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.
                    </p>
                    <p>
                        We use cookies to understand and save your preferences for future visits.
                    </p>
                    <h4>
                        Do we disclose any information to outside parties?
                    </h4>
                    <p>
                        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                    </p>
                    <h4>
                        Terms and Conditions
                    </h4>
                    <p>
                        Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at https://www.webfox.nz
                    </p>
                    <h4>
                        Your Consent
                    </h4>
                    <p>
                        By using our site, you consent to our privacy policy.
                    </p>
                    <h4>
                        Changes to our Privacy Policy
                    </h4>
                    <p>
                        If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.
                    </p>
                    <p>
                        This policy was last modified on 15 March 2011
                    </p>
                    <h4>
                        Contacting Us
                    </h4>
                    <p>
                        If there are any questions regarding this privacy policy you may contact us by visiting <Link to="/contact" className="button icon-right">https://www.webfox.nz/contact</Link>
                    </p>

                </div>
            </section>


        </Layout>
    )

}
